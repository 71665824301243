import React, { Component } from 'react';
import {
    Form,
    Input,
    Modal,
    Radio,
    Select,
    Spin,
} from 'antd';
import { getUsertOpt } from '../../api/user'

import $$ from 'cmn-utils';
import './index.less';
const { debounce } = $$;
const { Option } = Select;

export default class ClientForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userOptions: [],
            fetching: false,
            loading: false
        };
        this._throttleFilterUser = debounce(this._filterUser, 500)

    }
    componentWillMount() {

    }
    componentDidMount() {
    }
    _filterUser = (query) => {
        if (!query.trim()) {
            return false;
        }
        // let arr = list.filter(user => {
        //     if (user.realName && user.realName.indexOf(query) !== -1) {
        //         return true;
        //     }
        //     if (user.email && user.email.indexOf(query) !== -1) {
        //         return true;
        //     }
        // });
        getUsertOpt({
            name: query,
            type: 0
        }).then(res => {
            this.setState({
                userOptions: res,
                fetching: false
            });
        })

    }
    renderType = (type) => {
        let typeMap = ['趣头条', '集团', '外部账号'];
        return typeMap[parseInt(type) - 1]
    }


    render() {
        const { userOptions, fetching } = this.state;
        const { form, editData } = this.props;
        const { getFieldDecorator } = form;
        console.log(222,editData)

        return (
            <div className="user-page">
                <Modal
                    title={editData.client_id ? '编辑' : '新增应用'}
                    onOk={(e) => {
                        e.preventDefault();
                        this.props.form.validateFieldsAndScroll((err, values) => {
                            if (!err) {
                                console.log('Received values of form: ', values);
                                this.props.onSaveData(values)
                            }
                        });
                    }}
                    okText="保存"
                    width={600}
                    cancelText='取消'
                    onCancel={() => {
                        this.props.changeVisible(false)
                    }}
                    visible={this.props.modalVisible}
                >
                    <Form layout="inline" >
                        <Form.Item label="应用ID" className="mb20">
                            {getFieldDecorator('client_id', {
                                initialValue: editData.client_id || '',
                                rules: [{ required: true, message: '请输入应用ID!' }],
                            })(<Input
                                size='large'
                                disabled={!!editData.client_id}
                                placeholder="请输入应用ID"
                            />)}
                        </Form.Item>
                        <Form.Item label="应用名" className="mb20">
                            {getFieldDecorator('client_name', {
                                initialValue: editData.client_name || '',
                                rules: [{ required: true, message: '请输入应用名!' }],
                            })(<Input
                                size='large'
                                placeholder="请输入应用名"
                            />)}
                        </Form.Item>
                        <Form.Item label="回调url" className="mb20">
                            {getFieldDecorator('redirect_uri', {
                                initialValue: editData.redirect_uri || '',
                                rules: [{ required: true, message: '请输入回调url!' }],
                            })(<Input
                                size='large'
                                placeholder="请输入回调url"
                            />)}
                        </Form.Item>
                        <Form.Item label="登出url" className="mb20">
                            {getFieldDecorator('logout_uri', {
                                initialValue: editData.logout_uri || '',
                                rules: [{ required: true, message: '请输入登出url!' }],
                            })(<Input
                                size='large'
                                placeholder="请输入登出url"
                            />)}
                        </Form.Item>

                        <Form.Item label="类型" className="mb20">
                            {getFieldDecorator('type', {
                                initialValue: editData.type || '1',
                                rules: [{ required: false, message: '请选择类型!' }],
                            })(<Radio.Group>
                                <Radio.Button value={1}>趣头条</Radio.Button>
                                <Radio.Button value={2}>集团</Radio.Button>
                                <Radio.Button value={3}>全部</Radio.Button>
                                <Radio.Button value={4}>全不</Radio.Button>
                            </Radio.Group>)}
                        </Form.Item>

                        <Form.Item label="域名" className="mb20">
                            {getFieldDecorator('domain', {
                                initialValue: editData.domain || '',
                                rules: [{ required: false, message: '请输入域名!' }],
                            })(<Input
                                size='large'
                                placeholder="请输入域名"
                            />)}
                        </Form.Item>
                        <Form.Item label="申请人" className="mb20">

                            {getFieldDecorator('apply_uid', {
                                initialValue: editData.apply_uid || '',
                                rules: [{ required: true, message: '请选择申请人!' }],
                            })(
                                <Select
                                    showSearch
                                    placeholder="搜索用户"
                                    notFoundContent={fetching ? <Spin size="small" /> : null}
                                    optionLabelProp="name"
                                    onSearch={(qurey) => {
                                        this.setState({
                                            fetching: true
                                        })
                                        this._throttleFilterUser(qurey)
                                    }}
                                    filterOption={
                                        () => {
                                            return true
                                        }
                                    }
                                    style={{ width: '100%' }}
                                >
                                    {userOptions.map(item => (
                                        <Option key={item.id} name={item.realName}>
                                            <span>{item.realName}</span>
                                            <span style={{ marginLeft: '10px', fontSize: '12px' }}>{item.email}</span>
                                            <span style={{ marginLeft: '10px', fontSize: '12px' }}>{this.renderType(item.type)}</span>
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="access_token" className="mb20">
                            {getFieldDecorator('access_token_expires', {
                                initialValue: editData.access_token_expires || '',
                                rules: [{ required: true, message: 'access_token过期时间!' }],
                            })(<Input
                                size='large'
                                placeholder="access_token过期时间 默认28800秒"
                            />)}
                        </Form.Item>
                        <Form.Item label="refresh_token" className="mb20">
                            {getFieldDecorator('refresh_token_expires', {
                                initialValue: editData.refresh_token_expires || '',
                                rules: [{ required: true, message: 'refresh_token过期时间!' }],
                            })(<Input
                                size='large'
                                placeholder="refresh_token过期时间 默认86400秒"
                            />)}
                        </Form.Item>
                    </Form>
                </Modal>

            </div>
        )
    }
}