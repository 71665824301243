import React, { Component } from 'react';
import {
    Form,
    Input,
    Table,
    Button,
    message,
    Tag
} from 'antd';
import { getUserList, getClientList, getGroupList, addGroupToUser, addClientToUser } from '../../api/user'
import { getPagination } from '../../utils/util'
import AddForm from './addForm'

import './index.less';

const colors = ["magenta", "red", "volcano", "orange", "gold", "lime", "green", "cyan", "blue", "geekblue", "purple",]

export default class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filter: {
                name: '',
            },
            uid: '',
            formType: '',
            modalVisible: false,
            pagination: {},
            clientOpt: [],
            groupOpt: [],
            loading: false
        };
        this.columns = [
            {
                title: '姓名',
                dataIndex: 'realName',
                render: (text, item) => {
                    return item.user.realName
                }
            },
            {
                title: '邮箱',
                dataIndex: 'email',
                render: (text, item) => {
                    return item.user.email
                }
            },
            {
                title: '应用',
                dataIndex: 'department',
                render: (text, item) => {
                    return this._renderTag(item.clients)

                }
            },
            {
                title: '应用组',
                dataIndex: 'groups',
                render: (text, item) => {
                    return this._renderTag(item.groups)
                }
            },
            {
                title: '操作',
                dataIndex: '',
                key: 'x',
                render: (text, item) => {
                    return (
                        <div className="">
                            <a href="javascript:;" className="a-btn" onClick={
                                () => {
                                    this._RClient(item.clients, item.user.id)
                                }
                            }>关联应用</a>
                            <a href="javascript:;" className="a-btn danger ml10" onClick={
                                () => {
                                    this._RGroup(item.groups, item.user.id)
                                }
                            }>关联应用组</a>
                        </div>

                    )
                }
            },
        ];
    }

    _renderTag(data) {
        return (
            data.map((t, i) => {
                let index = parseInt(Math.random() * 11) + 1;
                let color = colors[index - 1];
                return (
                    <Tag key={i} color={color} style={{ marginTop: '10px', fontSize: '13px' }}>{t.client_name || t.group_name}</Tag>
                )

            })
        )
    }

    componentWillMount() {

    }
    componentDidMount() {
        this.getOptList()
    }
    getOptList = () => {
        getClientList({
            size: 10000,
            page: 1
        }).then(res => {
            this.setState({
                clientOpt: res.data
            })
        })
        getGroupList({
            size: 10000,
            page: 1
        }).then(res => {
            this.setState({
                groupOpt: res.data
            })
        })

    }
    setPagination({ count, page, size }) {
        const pagination = getPagination(count, page, size = 10000);
        this.setState({
            pagination,
        })

    }
    getList = (page = 1) => {
        this.setState({
            loading: true
        })
        getUserList({
            ...this.state.filter,
            page: page,
        }).then(res => {
            this.setPagination(res);
            this.setState({
                data: res,
                loading: false
            })
        }).catch(err => {
            this.setState({
                loading: false
            })
        })
    }
    goToDetail = ({ uid }) => {
        this.props.history.push(`/user/detail/${uid}`)
    }
    changePage = (page) => {
        this.getList(page);
    }
    changeFilter = (v, key) => {
        let tpl = {};
        tpl[key] = v.trim();
        this.setState({
            filter: Object.assign({}, this.state.filter, tpl)
        })
    }

    _onSaveData = (data) => {
        if (this.state.formType === 'client') {
            addClientToUser({
                uid: this.state.uid,
                client_ids: data.join(),
            }).then(res => {
                message.success('关联成功');
                this.setState({
                    modalVisible: false,
                }, () => {
                    this.getList()
                })
            }).catch(err => {
                message.error(err || '关联失败');
            })
        } else {
            addGroupToUser({
                uid: this.state.uid,
                group_ids: data.join(),
            }).then(res => {
                message.success('关联成功');
                this.setState({
                    modalVisible: false,
                    editData: {}
                }, () => {
                    this.getList()
                })
            }).catch(err => {
                message.error(err || '关联失败');
            })
        }

    }
    _changeVisible = (status) => {
        this.setState({
            modalVisible: status
        })
    }

    _RClient = (item, uid) => {
        let ids = item.map(v => {
            return v.client_id
        })
        this.setState({
            uid,
            modalVisible: true,
            editData: ids,
            opts: this.state.clientOpt,
            formType: 'client'
        })
    }

    _RGroup = (item, uid) => {
        let ids = item.map(v => {
            return v.group_id
        })
        this.setState({
            uid,
            modalVisible: true,
            editData: ids,
            opts: this.state.groupOpt,
            formType: 'group'
        })
    }

    render() {
        const { data, pagination, loading, editData, formType, opts } = this.state;
        return (
            <div className="user-page">
                <div className="nav">
                    <p>用户列表</p>
                </div>
                <header className="search-bar">
                    <Form layout="inline" >
                        <Form.Item label="姓名">
                            <Input
                                size='large'
                                allowClear
                                placeholder="请输入用户姓名"
                                onChange={
                                    (e) => {
                                        this.changeFilter(e.target.value, 'name')
                                    }
                                }
                                onPressEnter={() => {
                                    this.getList()
                                }}
                            />
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary" htmlType="submit"
                                onClick={() => {
                                    this.getList()
                                }}>
                                查询
                             </Button>
                        </Form.Item>
                    </Form>
                </header>
                <div className="content">
                    <Table
                        rowKey="user.id"
                        locale={{
                            emptyText: (<span>
                                无相关数据
                            </span>)
                        }}
                        columns={this.columns}
                        dataSource={data}
                        loading={loading}
                        pagination={{  // 分页
                            ...pagination,
                            onChange: (page) => {
                                this.changePage(page)
                            },
                        }} />
                </div>
                {
                    this.state.modalVisible && <AddForm editData={editData} opts={opts} formType={formType} onSaveData={this._onSaveData} modalVisible={this.state.modalVisible} changeVisible={this._changeVisible}></AddForm>
                }

            </div>
        )
    }
}