import { Base64 } from 'js-base64';
const WX_CONFIG = JSON.parse(process.env.WX_CONFIG);


// const WX = {
//     DEFAULT_WX_WAPPER_ID: 'wx_reg',
//     APPID: 'ww2cb3d75879c33965',
//     AGENTID: 1000079,
//     REDIRECT_URL: encodeURIComponent('https://login.qttcs3.cn/api/login/oauth-callback')
// };




export default {

    WwLogin(id = WX_CONFIG.DEFAULT_WX_WAPPER_ID) {
        let arg = this.getUrlSearch(),
            state = JSON.stringify({
                state: arg.state || '',
                client_id: arg.client_id || '',
                redirect_uri: arg.redirect_uri || '',
                project: arg.project || '',
                is_qa: arg.is_qa || ''
            })
        let b64 = Base64.encode(state).toString();
        window.WwLogin({
            id,
            appid: WX_CONFIG.APPID,
            agentid: WX_CONFIG.AGENTID,
            redirect_uri: WX_CONFIG.REDIRECT_URL,
            href: '',
            state: b64,
            class: "test"
        });
    },
    WwGetCode() {
        const SCOPE = 'snsapi_userinfo';
        let arg = this.getUrlSearch(),
            state = JSON.stringify({
                state: arg.state || '',
                client_id: arg.client_id || '',
                redirect_uri: arg.redirect_uri || '',
                project: arg.project || '',
                is_qa: arg.is_qa || ''


            })
        let b64 = Base64.encode(state).toString();
        const URL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${WX_CONFIG.APPID}&redirect_uri=${WX_CONFIG.REDIRECT_URL}&response_type=code&scope=${SCOPE}&agentid=${WX_CONFIG.AGENTID}&state=${b64}#wechat_redirect`
        window.location.href = URL;
    },
    SSO() {
        let arg = this.getUrlSearch(),
            source = encodeURIComponent(arg.source) || '',
            state = encodeURIComponent(arg.state) || '',
            client_id = arg.client_id || '',
            redirect_uri = encodeURIComponent(arg.redirect_uri) || '',
            project = arg.project || '',
            is_qa = arg.is_qa || ''


        const URL = `/api/oauth/sso?state=${state}&client_id=${client_id}&redirect_uri=${redirect_uri}&project=${project}&is_qa=${is_qa}`
        if (state === 'permissionSso') {
            window.location.href = `https://login.qutoutiao.net/api/oauth/permission-sso?state=${state}&client_id=${client_id}&redirect_uri=${redirect_uri}&project=${project}&is_qa=${is_qa}`;
        } else if (source === 'golangSso') {
            window.location.href = `https://login.qutoutiao.net/api/oauth/golang-sso?state=${state}&client_id=${client_id}&redirect_uri=${redirect_uri}&project=${project}&is_qa=${is_qa}`;
        } else if (redirect_uri) {
            window.location.href = URL;
        } else {
            window.location.href = '/start';

        }
    },
    isEwx() {
        return navigator.userAgent.includes('wxwork');
    },
    getUrlSearch() {
        let search, items, item, name, value, args = {};
        search = window.location.search.length > 0 ? window.location.search.substring(1) : null;
        items = search ? search.split("&") : "";
        for (let i = 0, len = items.length; i < len; i++) {
            item = items[i].split("=");
            name = decodeURIComponent(item[0]);
            value = (item[1] || '').replace(/(.*)%$/, (match, $1) => {
                return $1 + encodeURIComponent('%');
            });
            value = decodeURIComponent(value || '');
            if (name.length > 0) { //防止为空的参数比如说(?page=2&=invalidParam)
                args[name] = value;
            }
        }
        return args;
    },

};