import {combineReducers} from 'redux';
import CommonReducer from './common';
import DashBoardReducer from './dashboard';

import {connectRouter} from "connected-react-router";


export default (history) => combineReducers({
    CommonReducer,
    DashBoardReducer,
    router: connectRouter(history),
})