import Login from '../pages/login';
import LoginH5 from '../pages/login/h5';
import Layout from '../pages/layout';
import User from '../pages/user'
import Index from '../pages/home'
import Client from '../pages/client'
import Group from '../pages/group'
import LoginLog from '../pages/logs/loginLog'
import OperatorLog from '../pages/logs/operatorLog'
import AuthList from '../pages/user/authList'
import Start from '../pages/home/start';




export function getFlatMenu(menus) {
    let menu = [];
    menus.forEach(item => {
        if (item.children) {
            menu = menu.concat(getFlatMenu(item.children));
        }
        menu.push(item);
    });
    return menu;
}




export const defaultRouter = [
    {
        path: "/",
        component: Index,
        exact: true,
    },
    {
        path: "/user",
        component: Layout,
        routes: [
            {
                path: "/user/list",
                component: User
            },
            {
                path: "/user/client",
                component: Client
            },
            {
                path: "/user/group",
                component: Group
            },
            {
                path: "/user/loginlogs",
                component: LoginLog
            },
            {
                path: "/user/operatorLogs",
                component: OperatorLog
            },
            {
                path: "/user/authList",
                component: AuthList
            }
        ]
    },
    {
        path: "/start",
        component: Start,
    },
    {
        path: "/login/login",
        component: Login
    },
    {
        path: "/login-h5",
        component: LoginH5
    }
]




export const initMenu = [
    {
        name: '用户列表',
        icon: 'user',
        path: '/user/list',
    },
    {
        name: '应用列表',
        icon: 'link',
        path: '/user/client',
    },
    {
        name: '应用组列表',
        icon: 'appstore',
        path: '/user/group',
    },
    {
        name: '登录日志',
        icon: 'file',
        path: '/user/loginlogs',
    }, {
        name: '操作日志',
        icon: 'mobile',
        path: '/user/operatorLogs',
    }, {
        name: '权限控制',
        icon: 'team',
        path: '/user/authList',
    },
];


export const flatMenu = getFlatMenu(initMenu);



