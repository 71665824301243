import React, { Component } from 'react';
import {
    Form,
    Input,
    Table,
    Button,
    Modal,
    message,
} from 'antd';
import { getClientList, postClientInfo, updateClientInfo, delClientInfo } from '../../api/user'
import { getPagination } from '../../utils/util'
import './index.less';
import ClientForm from './ClientForm'

const confirm = Modal.confirm;

export default class Client extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filter: {
                client_name: '',
                client_id:''
            },
            formType: 'new',
            pagination: {},
            editData: {},
            modalVisible: false,
            loading: false
        };

        this.columns = [
            {
                title: '应用信息',
                dataIndex: 1,
                render: (text, item) => {
                    return (
                        <div className="client">
                       
                            <p>
                                <span className="key">名称:</span>
                                <span>{item.client_name}</span>
                            </p>
                            <p>
                                <span className="key">类型:</span>
                                <span >{this.renderType(item.type)}</span>
                            </p>


                        </div>
                    )
                }
            },
            {
                title: '密钥信息',
                dataIndex: 1,
                width: "25%",
                render: (text, item) => {
                    return (
                        <div className="client">
                            <p>
                                <span className="key">id:</span>
                                <span>{item.client_id}</span>
                            </p>
                           
                            <p>
                                <span className="key">密钥:</span>
                                <span style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{this._renderText(item.client_secret)}</span>
                            </p>
                            <p>
                                <span className="key">domain:</span>
                                <span>{this._renderText(item.domain)}</span>
                            </p>

                        </div>
                    )
                }
            },
            {
                title: '回调信息',
                dataIndex: 2,
                width: "25%",
                render: (text, item) => {
                    return (
                        <div className="">
                            <p>
                                <span className="key">登录:</span>
                                <span style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{this._renderText(item.redirect_uri)}</span>
                            </p>
                            <p>
                                <span className="key">登出:</span>
                                <span style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{this._renderText(item.logout_uri)}</span>
                            </p>
                        </div>
                    )
                }
            },
            {
                title: '过期时间(s)',
                dataIndex: 3,
                render: (text, item) => {
                    return (
                        <div className="">
                            <p>
                                <span className="key">accessToken:</span>
                                <span >{item.access_token_expires}</span>
                            </p>
                            <p>
                                <span className="key">refreshToken:</span>
                                <span c>{item.refresh_token_expires}</span>
                            </p>
                        </div>
                    )
                }
            },
            {
                dataIndex: 4,
                title: '维护时间',
                render: (text, item) => {
                    return (
                        <div className="">
                            <p>
                                <span className="key">更新时间:</span>
                                <span>{this._renderText(item.update_time)}</span>
                            </p>
                            <p>
                                <span className="key">创建时间:</span>
                                <span>{this._renderText(item.create_time)}</span>
                            </p>
                        </div>
                    )
                }
            },

            {
                title: '人员信息',
                dataIndex: 6,
                render: (text, item) => {
                    return (
                        <div className="">
                            <p>
                                <span className="key">创建人:</span>
                                <span>{this._renderText(item.operator)}</span>
                            </p>
                            <p>
                                <span className="key">申请人:</span>
                                <span>{this._renderText(item.apply_user)}</span>
                            </p>
                        </div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: '',
                dataIndex: '8',
                render: (text, item) => {
                    return (
                        <div className="">
                            <a href="javascript:;" className="a-btn" onClick={
                                () => {
                                    this._edit(item)
                                }
                            }>编辑</a>
                            <a href="javascript:;" className="a-btn danger ml10" onClick={
                                () => {
                                    this._showDeleteConfirm({ client_id: item.client_id, client_name: item.client_name })
                                }
                            }>删除</a>
                        </div>

                    )
                }



            },
        ];
    }
    componentWillMount() {

    }
    componentDidMount() {
        this.getList();
    }
    setPagination({ total, page, pageSize }) {
        const pagination = getPagination(total, page, pageSize);
        this.setState({
            pagination,
        })

    }
    renderType = (type) => {
        let strmap = ['异常', '趣头条', '集团', '全部', ' 全不'];
        return strmap[parseInt(type)]
    }
    getList = (page = 1) => {
        this.setState({
            loading: true
        })
        getClientList({
            ...this.state.filter,
            page: page,
            size: 20
        }).then(res => {
            this.setPagination(res);
            this.setState({
                data: res.data,
                loading: false
            })
        }).catch(err => {
            this.setState({
                loading: false
            })
        })
    }
    changePage = (page) => {
        this.getList(page);
    }
    changeFilter = (v, key) => {
        let tpl = {};
        tpl[key] = v;
        this.setState({
            filter: Object.assign({}, this.state.filter, tpl)
        })
    }
    _renderText = (text) => {
        // let len = text.length;
        // if (len > 100) {
        //     return (
        //         <Tooltip placement="topLeft" title={text}>
        //             <span>{`${text.substring(0, 40)}...`}</span>
        //         </Tooltip>
        //     )
        // } else {
        //     return text
        // }
        return text

    }

    _changeVisible = (status) => {
        this.setState({
            modalVisible: status
        })
    }

    _onSaveData = (data) => {
        if (this.state.formType === 'new') {
            postClientInfo(data).then(res => {
                message.success('添加成功');

                this.setState({
                    modalVisible: false,
                }, () => {
                    this.getList()
                })
            }).catch(err => {
                message.error(err || '添加失败');
            })
        } else {
            if (data.apply_uid === '') {
                data.apply_uid = 0;
            }
            updateClientInfo(data).then(res => {
                message.success('修改成功');
                this.setState({
                    modalVisible: false,
                    editData: {}
                }, () => {
                    this.getList()
                })
            }).catch(err => {
                message.error(err || '修改失败');
            })
        }

    }
    _edit = (item) => {
        this.setState({
            modalVisible: true,
            editData: item || {},
            formType: 'edit'
        })
    }

    _showDeleteConfirm = ({ client_id, client_name }) => {
        let me = this;
        confirm({
            title: `您确认删除${client_name}应用吗?`,
            content: '删除后不可恢复',
            okText: '确认删除',
            okType: 'danger',
            cancelText: '取消',
            onOk: () => {
                delClientInfo(client_id).then(res => {
                    message.success('删除成功')
                    me.getList();
                })
            }
        });
    }

    render() {
        const { data, pagination, loading, editData } = this.state;
        const EnhancedForm = Form.create()(ClientForm);
        return (
            <div className="client-page">
                <div className="nav">
                    <p>应用列表</p>
                </div>
                <header className="search-bar">
                    <Form layout="inline" >
                        <Form.Item label="应用名">
                            <Input
                                size='large'
                                allowClear
                                placeholder="请输入应用名"
                                onChange={
                                    (e) => {
                                        this.changeFilter(e.target.value, 'client_name')
                                    }
                                }
                                onPressEnter={() => {
                                    this.getList()
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="应用ID">
                            <Input
                                size='large'
                                allowClear
                                placeholder="请输入应用ID"
                                onChange={
                                    (e) => {
                                        this.changeFilter(e.target.value, 'client_id')
                                    }
                                }
                                onPressEnter={() => {
                                    this.getList()
                                }}
                            />
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary" htmlType="submit"
                                onClick={() => {
                                    this.getList()
                                }}>
                                查询
                             </Button>
                        </Form.Item>

                        <Form.Item >
                            <Button className="success-btn" htmlType="submit"
                                onClick={() => {
                                    this.setState({
                                        modalVisible: true,
                                        editData: {},
                                        formType: 'new'
                                    })
                                }}>
                                新增应用
                             </Button>
                        </Form.Item>

                    </Form>
                </header>
                <div className="content">
                    <Table
                        rowKey="uid"
                        locale={{
                            emptyText: (<span>
                                无相关数据
                            </span>)
                        }}
                        columns={this.columns}
                        dataSource={data}
                        loading={loading}
                        pagination={{  // 分页
                            ...pagination,
                            onChange: (page) => {
                                this.changePage(page)
                            },
                        }} />
                </div>
                <EnhancedForm wrappedComponentRef={(form) => this.form = form} editData={editData} onSaveData={this._onSaveData} modalVisible={this.state.modalVisible} changeVisible={this._changeVisible} />
            </div>
        )
    }
}