import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import reducers from '../reducers';
import createHistory from "history/createBrowserHistory";
import {routerMiddleware} from "connected-react-router";

const historyM = createHistory();
const history = routerMiddleware(historyM);
const middlewares = [
    thunk,
    history
];
let createAppStore = applyMiddleware(...middlewares)(createStore)


  
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['CommonReducer']
};


const persistedReducer = persistReducer(persistConfig, reducers(historyM));


let store = createAppStore(persistedReducer)

let persistor = persistStore(store)

export{
    store,
    persistor,
    historyM
} 
