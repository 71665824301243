
export const SET_USER_INFO = 'SET_USER_INFO';

export const LOGOUT = 'LOGOUT';

export const SET_MENUS = 'SET_MENUS';


export const SET_NOTICE = 'SET_NOTICE';
export const SET_LINES = 'SET_LINES';











