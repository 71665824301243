import React, { Component } from 'react';
import {
    Modal,
    Select,
} from 'antd';

import './index.less';
const { Option } = Select;

export default class addclient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectValue: []
        };

    }
    componentWillMount() {

    }
    componentDidMount() {
    }

    render() {
        const { editData, opts = [] } = this.props;
        const { selectValue } = this.state;
        const filteredOptions = opts.filter(o => !(selectValue.includes(o.group_id) || selectValue.includes(o.client_id)));
        return (
            <div className="user-page">
                <Modal
                    title='选择关联'
                    onOk={() => {
                        this.props.onSaveData(this.state.selectValue)
                    }}
                    okText="保存"
                    cancelText='取消'
                    onCancel={() => {
                        this.props.changeVisible(false)
                    }}
                    visible={this.props.modalVisible}
                >
                    <p>
                        选择应用:
                    </p>
                    <Select
                        mode="multiple"
                        placeholder="请选择"
                        optionLabelProp="name"
                        onChange={(value) => {
                            this.setState({
                                selectValue: value
                            })
                        }}
                        filterOption={(inputValue, option) => {
                            if (option.props.name.indexOf(inputValue) !== -1) {
                                return true
                            } else {
                                return false
                            }

                        }}
                        defaultValue={editData}
                        style={{ width: '100%', marginBottom: '50px' }}
                    >
                        {
                            filteredOptions.map(item => (
                                <Option key={item.client_id} name={item.client_name}>
                                    <span>{item.client_name}</span>
                                </Option>
                            ))
                        }

                    </Select>
                </Modal>
            </div>
        )
    }
}