import React from 'react';
import { Layout } from 'antd';
import NavBar from './components/NavBar';
import { LeftSideBar } from './components/SideBar';
import pathToRegexp from 'path-to-regexp';
import { enquireIsMobile } from '../..//utils/enquireScrern/index';
import { renderRoutes } from "react-router-config";
import './styles/basic.less';
import { initMenu, getFlatMenu } from '../../router'
import { connect } from 'react-redux'
import { GetUserInfoAction } from '../../actions/Common'
// import { GetNoticeAction } from '../../actions/DashBoard'

import CacheRoute from 'react-router-cache-route'

const { Content, Header } = Layout;


class BasicLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    if (props.location.pathname === '/') {
      props.history.replace('/user')
    }
    const theme = {
      leftSide: 'light', // 左边
      navbar: 'light' // 顶部
    }
    if (!theme.layout) {
      theme.layout = [
        'fixedHeader',
        'fixedSidebar',
        'fixedBreadcrumbs'
      ];
    }
    this.state = {
      userInfoLoaded: false,
      collapsedLeftSide: false, // 左边栏开关控制
      leftCollapsedWidth: 60, // 左边栏宽度
      theme,
      menus: [],
      flatMenu: [],
      user: {},
      currentMenu: {},
      isMobile: false
    };
  }

  componentDidMount() {
    this.unregisterEnquire = enquireIsMobile(ismobile => {
      const { isMobile } = this.state;
      if (isMobile !== ismobile) {
        this.setState({
          isMobile: ismobile
        });
      }
    });
    let { menus } = this.props.CommonReducer;
    this.setMenusState(menus);
  }

  componentWillMount() {
    let { dispatch } = this.props;
    dispatch(GetUserInfoAction((res = {}) => {
      if (!res.uid) {
      } else {
        this.setState({
          userInfoLoaded: true
        })
      }
    }))

    // dispatch(GetMenusAction((res = {}) => {
    //   if (!res.uid) {
    //     // window.location.replace('http://dev.risk.qttcs3.cn/login/login')
    //   } else {
    //     this.setState({
    //       userInfoLoaded: true
    //     })
    //   }
    // }))

    // dispatch(GetNoticeAction());

  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.location.pathname !== this.props.location.pathname
    ) {
      this.setState({
        currentMenu: this.getCurrentMenu(nextProps) || {}
      });
    }
    if (JSON.stringify(nextProps.CommonReducer.menus) !== JSON.stringify(this.props.CommonReducer.menus)) {
      this.setMenusState(nextProps.CommonReducer.menus);
    }
  }

  componentWillUnmount() {
    // 清理监听
    this.unregisterEnquire();
  }
  setMenusState = (menus) => {
    let nMenus = initMenu.concat(menus)
    let nFlatMenu = getFlatMenu(nMenus);
    this.setState({
      menus: nMenus,
      flatMenu: nFlatMenu,
    }, () => {
      // this.setState({
      //   currentMenu: this.getCurrentMenu()
      // })
    })
  }
  getCurrentMenu(props) {
    const {
      location: { pathname }
    } = props || this.props;
    let { flatMenu } = this.state;
    const menu = this.getMeunMatchKeys(flatMenu, pathname)[0];
    return menu;
  }

  getMeunMatchKeys = (flatMenu, path) => {
    return flatMenu.filter(item => {
      return pathToRegexp(item.path).test(path);
    });
  };

  /**
   * 顶部左侧菜单图标收缩控制
   */
  onCollapseLeftSide = _ => {
    const collapsedLeftSide =
      this.state.leftCollapsedWidth === 0
        ? true
        : !this.state.collapsedLeftSide;

    this.setState({
      collapsedLeftSide,
      leftCollapsedWidth: 60
    });
  };


  render() {
    const {
      collapsedLeftSide,
      leftCollapsedWidth,
      theme,
      user,
      flatMenu,
      menus,
      currentMenu,
      userInfoLoaded,
      isMobile
    } = this.state;
    const { route, location } = this.props;
    let { notice } = this.props.DashBoardReducer;

    if (!userInfoLoaded) {
      return null
    }
    let keepAliveRouter = [], routes = [];
    route.routes.forEach(r => {
      if (r.keepAlive) {
        keepAliveRouter.push(r)
      } else {
        routes.push(r)
      }
    })
    return (
      <Layout className='basic-layout full-layout fixed fixed-header fixed-breadcrumbs ant-layout'>
        <Header>
          <NavBar
            {
            ...this.props
            }
            notice={notice}
            collapsed={collapsedLeftSide}
            onCollapseLeftSide={this.onCollapseLeftSide}
            theme={theme.navbar}
            user={user}
            isMobile={isMobile}
          />
        </Header>
        <Layout>
          <LeftSideBar
            collapsed={collapsedLeftSide}
            leftCollapsedWidth={leftCollapsedWidth}
            onCollapse={this.onCollapseLeftSide}
            location={location}
            theme={theme.leftSide}
            flatMenu={flatMenu}
            currentMenu={currentMenu}
            menu={menus}
            user={user}
            isMobile={isMobile}
          />
          <Content>
            <Layout className="full-layout">
              <Header>
              </Header>
              <Content className="router-page">
                {
                  keepAliveRouter.map((router, index) => {
                    return <CacheRoute key={index} exact path={router.path} component={router.component} when="always" />
                  })
                }
                {renderRoutes(routes)}
              </Content>
            </Layout>
          </Content>
        </Layout>
      </Layout>
    );
  }
}



export default connect((state) => {
  const { CommonReducer, DashBoardReducer } = state;
  return {
    CommonReducer, DashBoardReducer
  }
})(BasicLayout);