import * as types from '../actions/ActionTypes'



const initialState = {
    userInfo: {},
    menus:[]
}

let Common = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_USER_INFO:
            return Object.assign({}, state, {
                userInfo: action.userInfo
            })
        case types.SET_MENUS:
            return Object.assign({}, state, {
                menus:action.menus,
            })
        default:
            return state;
    }
}
export default Common;