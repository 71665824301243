import * as types from '../actions/ActionTypes'
const initialState = {
    notice: [],
    lines: []
}

let Common = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_NOTICE:
            return Object.assign({}, state, {
                notice: action.notice
            })
        case types.SET_LINES:
            return Object.assign({}, state, {
                lines: action.lines,
            })
        default:
            return state;
    }
}
export default Common;