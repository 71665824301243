export let getTime = (timestamp) => {
    if (!timestamp) {
        return {
            h: 0,
            m: 0,
            s: 0
        }
    };
    // timestamp *= 1000;
    let nowTime = new Date().getTime();
    let diffTime = (nowTime - timestamp) / 1000;
    let res = {
        h: 0,
        m: 0,
        s: 0
    };
    if (parseInt(diffTime / 3600) > 0) {
        res.h = parseInt(diffTime / 3600);
        diffTime -= res.h * 3600;
    }
    if (parseInt(diffTime / 60) > 0) {
        res.m = parseInt(diffTime / 60);
        diffTime -= res.m * 60;
    }
    res.s = parseInt(diffTime);
    return res;
}
export let secondTimer = (time) => {
    time.s += 1;
    if (time.s === 60) {
        time.s = 0;
        time.m += 1;
        if (time.m === 60) {
            time.m = 0;
            time.h += 1;
        }
    };
    return time;
}

export const clean = (obj) => {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName];
        }
    }
}

export const getUrlArgs = (search) => {
    let urlArr = search.split("?"),
        json = {};
    if (urlArr[1]) {
        var args = urlArr[1].split("&");
        for (var i = 0; i < args.length; i++) {
            var tmpArr = args[i].split("=");
            json[tmpArr[0]] = tmpArr[1];
        }
    }
    return json;
}



export const getPagination = (count = 0, page = 1, size = 20) => {
    let pagination = {
        current: parseInt(page),
        pageSize: parseInt(size),
        total: parseInt(count),
        hideOnSinglePage: true,
    }
    return pagination;
}

export const deepClone = (source) => {
    if (!source && typeof source !== 'object') {
        throw new Error('error arguments', 'shallowClone');
    }
    const targetObj = source.constructor === Array ? [] : {};
    for (const keys in source) {
        if (source.hasOwnProperty(keys)) {
            if (source[keys] && typeof source[keys] === 'object') {
                targetObj[keys] = source[keys].constructor === Array ? [] : {};
                targetObj[keys] = deepClone(source[keys]);
            } else {
                targetObj[keys] = source[keys];
            }
        }
    }
    return targetObj;
}
 
export const scrollToTop = ()=>{
    document.getElementsByClassName('ant-layout-content')[1].scrollTo(0,0)
}


