import React, { PureComponent } from 'react';
import Icon from '../Icon';
import { Popover, Badge, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { connect } from 'react-redux'
import './style/index.less';
import { Logout } from '../../../../api/login'
/**
 * 其本本局头部区域
 */
class NavBar extends PureComponent {
  state = {
  };

  static defaultProps = {
    fixed: true,
    theme: '' //'bg-dark',
  };



  render() {
    const {
      fixed,
      theme,
      onCollapseLeftSide,
      collapsed,
      notice,
      isMobile,
      CommonReducer
    } = this.props;

    const userInfo = CommonReducer.userInfo || {};
    const classnames = cx('navbar', {
      'navbar-fixed-top': !!fixed,
      'navbar-sm': isMobile ? true : collapsed,
      ['bg-' + theme]: !!theme
    });

    return (
      <header className={classnames}>
        <div className="navbar-branding">
          <Link className="navbar-brand" to="/">
            <b>统一账户中心</b>
          </Link>
          <span className="toggle_sidemenu_l" onClick={onCollapseLeftSide}>
            <Icon type="lines" />
          </span>
        </div>

        <ul className="nav navbar-nav navbar-right clearfix">

          <li className="dropdown">
            <Popover
              placement="bottomRight"
              title={`WELCOME ${userInfo.realName}`}
              overlayClassName={cx('navbar-popup', { [theme]: !!theme })}
              content={
                <UserDropDown
                  {...this.props}
                  notice={notice}
                />}
              trigger="click"
            >
              <a className="dropdown-toggle">
                <Badge dot>
                  <Avatar src={require('../../../../assets/images/avatar.jpg')}>
                  </Avatar>
                </Badge>
              </a>
            </Popover>
          </li>
        </ul>
      </header>
    );
  }
}

const UserDropDown = props => (

  <ul className="dropdown-menu list-group dropdown-persist">
    {
      props.notice.map(item => {
        return (
          <li className="list-group-item" key={item.key}>
            <a className="animated animated-short fadeInUp" onClick={() => {
              props.history.push(`/common/${item.key}`)
            }}
            >
              <Icon type="users" /> {item.name}
              <Badge count={item.count} className="label" />
            </a>
          </li>
        )
      })
    }
    <li className="list-group-item dropdown-footer">
      <a className="animated animated-short fadeInUp" onClick={() => {
        Logout().then(res => {
          props.history.replace('/login/login')
        })
      }}>
        <Icon type="poweroff" /> 退出
      </a>
    </li>
  </ul>
);

export default connect((state) => {
  const { CommonReducer } = state;
  return {
    CommonReducer
  }
})(NavBar);