import request from '../utils/request';


//用户登陆注册
export function getUserInfo() {
    return request({
        url: '/login/getuser',
        method: 'GET',
    });
}



export function getMenus() {
    return request({
        url: '/handle/menu',
        method: 'GET',
    });
}




export function Login(data) {
    return request({
        url: '/login/login-ad',
        method: 'POST',
        data
    });
}



export function Logout() {
    return request({
        url: '/login/logout',
        method: 'POST'
    });
}


