import React, { Component } from 'react';
import {
    Form,
    Icon,
    Input,
    Button,
    Radio,
    message
} from 'antd';
import { Login } from '../../api/login'
import { Base64 } from 'js-base64';
import wxAuth from '../../utils/wxAuth'

import './h5.less';


export default class Mb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            userName: '',
            password: '',
            activeTab: "qrcode",
            formType: "internal",
            organization: 'qtt'
        };

    }
    componentWillMount() {

    }
    componentDidMount() {

    }

    _Login = () => {
        let { userName, password, formType, organization } = this.state;
        if (!userName) {
            message.error('账号不能为空')
        } else if (!password) {
            message.error('密码不能为空')
        } else {
            let type = formType === 'external' ? 3 : (organization === 'qtt' ? 1 : 2);
            let userNameB64 = Base64.encode(userName).toString();
            let passwordB64 = Base64.encode(password).toString();
            Login({
                userName: userNameB64,
                password: passwordB64,
                // userName,
                // password,
                type
            }).then(res => {
                wxAuth.SSO();
            })
        }
    }


    render() {
        let { formType } = this.state;
        return (
            <div className="login-h5-page flex_v justify_between">
                <div className="top">
                    <div className="header-w">
                        <img src={require('../../assets/images/header.png')} className="header-img" alt="" />
                    </div>
                    <div className="login_form login-w">
                        <div className="tabs  flex_h">
                            <div className={['tab', formType === 'internal' ? 'active-form' : ''].join(' ')}
                                onClick={() => {
                                    this.setState({
                                        formType: 'internal'
                                    })
                                }}
                            ><span>内部用户</span></div>
                            <div className={['tab', formType === 'external' ? 'active-form' : ''].join(' ')}
                                onClick={() => {
                                    this.setState({
                                        formType: 'external'
                                    })
                                }}> <span>外部用户</span> </div>
                        </div>
                        <Form className="login-form">
                            <Form.Item>
                                <Input
                                    onChange={(e) => {
                                        this.setState({
                                            userName: e.target.value
                                        })
                                    }}
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="请输入域账号"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Input.Password
                                    onChange={(e) => {
                                        this.setState({
                                            password: e.target.value
                                        })
                                    }}
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="password"
                                    placeholder="请输入密码"
                                />
                            </Form.Item>
                            {
                                formType === 'internal' && <Form.Item>
                                    <Radio.Group onChange={(e) => {
                                        this.setState({
                                            organization: e.target.value
                                        })
                                    }} value={this.state.organization}>
                                        <Radio value={'qtt'} checked>趣头条</Radio>
                                        <Radio value={'group'}>集团</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            }

                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button" onClick={
                                    () => {
                                        this._Login()
                                    }
                                }>
                                    登录
                                </Button>
                            </Form.Item>
                        </Form>
                        <div className="tip">登录如有问题请企微咨询：PIEE服务</div>
                    </div>
                </div>
                <div className="rights">Qutoutiao@2019信息化与工程效率(PIEE)</div>
            </div>
        )
    }
}