import * as types from './ActionTypes';
import { getUserInfo, getMenus } from '../api/login';

export function GetUserInfoAction(cb) {
    return dispatch => {
        return getUserInfo().then(res => {
            cb && cb(res)
            dispatch(setUserInfo(res));
        })
    }

}

export let setUserInfo = (userInfo) => {
    return {
        type: types.SET_USER_INFO,
        userInfo: userInfo,
    }
};



export function GetMenusAction(cb) {
    return dispatch => {
        return getMenus().then(menus => {
            let nMenus = [];
            if (Array.isArray(menus)) {
                nMenus = menus.map(menu => {
                    menu.path = `/common/${menu.key}`
                    return menu
                })
            }
            cb && cb(nMenus)
            dispatch(setMenus(nMenus));
        })
    }
}

export let setMenus = (menus) => {
    return {
        type: types.SET_MENUS,
        menus: menus,
    }
};



// export function LoginOutAction(cb) {
//     return dispatch => {
//         return getUserInfo().then(res => {
//             cb&&cb(res)
//             dispatch(setUserInfo(res));
//         })
//     }

// }


// export let loginOut = () => {
//     return {
//         type: types.LOGOUT,
//     }
// };
