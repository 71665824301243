import React, { Component } from 'react';


export default class Index extends Component {
    constructor(props) {
        super(props);
        props.history.replace('/login/login')
    }
    componentWillMount() {

    }
    componentDidMount() {

    }


    render() {
        return null
    }
}