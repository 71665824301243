import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor, historyM } from './store';
import { ConnectedRouter } from "connected-react-router";
import { defaultRouter } from './router'
import { renderRoutes } from "react-router-config";
import { LocaleProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import { message } from 'antd';
import { getUrlArgs } from './utils/util'
import Cookies from 'js-cookie';

// {renderRoutes(defaultRouter)}

class App extends React.Component {
  constructor(props) {
    super(props);
    let errorMsg = decodeURIComponent(getUrlArgs(window.location.search).errorMsg);
    let errorCode = getUrlArgs(window.location.search).errorCode;


    if (getUrlArgs(window.location.search).errorCode) {
      if (errorCode == -3) {
        message.error(this.getA(), 0);
      } else {
        message.error(`${errorCode}:${errorMsg}`);
      }
    }

  }
  componentWillMount() {
    // console.log('componentWillMount')
    Cookies.remove('ewx-perm-token', { domain: '.qutoutiao.net' })
  }

  getA = () => {
    return (
      <a href="http://km.qutoutiao.net/pages/viewpage.action?pageId=106842114" target='_blank'>您无权限登录此系统，请点此处申请权限</a>
    )
  }

  render() {
    return (
      <LocaleProvider locale={zh_CN}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={historyM}>
              {renderRoutes(defaultRouter)}
            </ConnectedRouter>
          </PersistGate>
        </Provider>
      </LocaleProvider>
    )
  }
}


export default App
