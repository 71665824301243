import axios from 'axios';
// import { logout } from '../actions/Common'
import qs from 'qs';
import { message } from 'antd';

const API_CONFIG = JSON.parse(process.env.API_CONFIG);
console.log(2222, API_CONFIG)
message.config({
    top: 50,
    duration: 2,
    maxCount: 1,
});
// 创建axios实例
const Http = axios.create({
    baseURL: API_CONFIG.BASE_API, // api的base_url
    timeout: 100000, // 请求超时时间
});

// request拦截器
Http.interceptors.request.use(config => {
    let { params, data } = config;
    for (let key in params) {
        if (params.hasOwnProperty(key)) {
            if (params[key] === '' || params[key] === undefined) {
                delete params[key]
            }
        }
    }
    if (data) {
        config.data = qs.stringify(data)
    }
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    return config;
}, error => {
    console.log(error); // for debug
    Promise.reject(error);
})

// respone拦截器
Http.interceptors.response.use(
    response => {
        if (response.data.retCode === 10006) {
            // if (API_CONFIG.ENV === 'development') {
            //     window.location.replace(`${API_CONFIG.PROXY_URL}/login/login`)
            // } else {
            //     window.location.replace(`${API_CONFIG.BASE_API}/login/login`)
            // }
            window.location.replace(`/login/login`)

        } else if (response.data.retCode === 0) {
            return Promise.resolve(response.data.retData);
        } else {
            message.error(response.data.retMsg)
            return Promise.reject(response.data.retMsg);
        }
    },
    error => {
        console.log('err' + error); // for debug
        return Promise.reject(error);
    }
)

export default Http;
