import React, { Component } from 'react';
import {
    Form,
    Table,
    Button,
    Select,
    DatePicker,
    Spin
} from 'antd';
import { getLoginLogList } from '../../api/user'
import { getPagination } from '../../utils/util'

import './index.less';
import { getUsertOpt } from '../../api/user'

import $$ from 'cmn-utils';
const { debounce } = $$;
const { Option } = Select;

export default class OperatorLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filter: {
                client_id: '',
                uid: '',
                start_time: "",
                end_time: "",
            },
            userOptions: [],
            clientOpts: []
        };
        this.columns = [
            {
                title: '操作人',
                dataIndex: 'user_name'
            },
            {
                title: '操作平台',
                dataIndex: 'client_name'
            },
            {
                title: '操作平台ID',
                dataIndex: 'client_id'
            },
            {
                title: '操作数据',
                width: '20%',
                render: (text, item) => {
                    return (
                        <div className="client">
                            <p>
                                <span style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{item.detail}</span>
                            </p>
                        </div>
                    )
                }
            },
            {
                title: '操作结果',
                dataIndex: 'result'
            },
            {
                title: '操作时间',
                dataIndex: 'log_time'
            }
        ];
        this._throttleFilterUser = debounce(this._filterUser, 500)

    }


    componentWillMount() {

    }
    componentDidMount() {
        this.getList()
    }

    setPagination({ total, page, pageSize }) {
        const pagination = getPagination(total, page, pageSize);
        this.setState({
            pagination,
        })

    }
    getList = (page = 1) => {
        this.setState({
            loading: true
        })
        getLoginLogList({
            ...this.state.filter,
            page: page,
        }).then(res => {
            this.setPagination(res);
            this.setState({
                data: res.data,
                clientOpts: res.client,
                loading: false
            })
        }).catch(err => {
            this.setState({
                loading: false
            })
        })
    }
    _filterUser = (query) => {
        if (!query.trim()) {
            return false;
        }
        getUsertOpt({
            name: query,
            type: 0
        }).then(res => {
            this.setState({
                userOptions: res,
                fetching: false
            });
        })
    }

    changePage = (page) => {
        this.getList(page);
    }
    changeFilter = (v='', key, cb) => {
        let tpl = {};
        tpl[key] = v.trim();
        this.setState({
            filter: Object.assign({}, this.state.filter, tpl)
        }, () => {
            cb && cb()
        })
    }


    render() {
        const { data, pagination, loading, fetching, userOptions, clientOpts } = this.state;
        return (
            <div className="logs-page">
                <div className="nav">
                    <p>操作日志</p>
                </div>
                <header className="search-bar">
                    <Form layout="inline" >
                        <Form.Item label="应用名">
                            <Select
                                showSearch
                                allowClear
                                placeholder="请选择应用"
                                notFoundContent={fetching ? <Spin size="small" /> : null}
                                optionLabelProp="name"
                                onChange={(value) => {
                                    this.changeFilter(value, 'client_id', () => {
                                        this.getList()
                                    })

                                }}
                                filterOption={(input, option) =>
                                    option.props.children.props.children.indexOf(input) >= 0
                                }
                                style={{ width: '180px' }}
                            >
                                {clientOpts.map(item => (
                                    <Option key={item.client_id} name={item.client_name}>
                                        <span>{item.client_name}</span>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item label="操作者">
                            <Select
                                showSearch
                                allowClear
                                placeholder="搜索用户"
                                notFoundContent={fetching ? <Spin size="small" /> : null}
                                optionLabelProp="name"
                                onSearch={(qurey) => {
                                    this.setState({
                                        fetching: true
                                    })
                                    this._throttleFilterUser(qurey)
                                }}
                                onChange={(value) => {
                                    this.changeFilter(value, 'uid', () => {
                                        this.getList()
                                    })

                                }}
                                style={{ width: '180px' }}
                            >
                                {userOptions.map(item => (
                                    <Option key={item.id} name={item.realName}>
                                        <span>{item.realName}</span>
                                        <span style={{ marginLeft: '10px', fontSize: '12px' }}>{item.email}</span>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label='开始时间' key='start_time'>
                            <DatePicker
                                placeholder={`请选择开始时间`}
                                onChange={
                                    (mo, oValue) => {
                                        let nOValue = `${oValue} 00:00:00`
                                        this.changeFilter(nOValue, 'start_time', () => {
                                            this.getList()
                                        })
                                    }
                                } />
                        </Form.Item>
                        <Form.Item label='开始时间' key='end_time'>
                            <DatePicker
                                placeholder={`请选择截止时间`}
                                onChange={
                                    (mo, oValue) => {
                                        let nOValue = `${oValue} 00:00:00`
                                        this.changeFilter(nOValue, 'end_time', () => {
                                            this.getList()
                                        })
                                    }
                                } />
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary" htmlType="submit"
                                onClick={() => {
                                    this.getList()
                                }}>
                                查询
                             </Button>
                        </Form.Item>
                    </Form>
                </header>
                <div className="content">
                    <Table
                        rowKey="id"
                        locale={{
                            emptyText: (<span>
                                无相关数据
                            </span>)
                        }}
                        columns={this.columns}
                        dataSource={data}
                        loading={loading}
                        pagination={{  // 分页
                            ...pagination,
                            onChange: (page) => {
                                this.changePage(page)
                            },
                        }} />
                </div>


            </div>
        )
    }
}