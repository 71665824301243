import React, { Component } from 'react';
import { enquireIsMobile } from '../..//utils/enquireScrern/index';
import wxAuth from '../../utils/wxAuth'
import Web from './web'
import Mb from './h5'
export default class Login extends Component {
    constructor(props) {
        super(props);

        let isWx = wxAuth.isEwx();
        this.state = {
            isMobile: false,
            isWx
        }
        if (isWx) {
            wxAuth.WwGetCode();
        }
    }
    componentWillMount() {

    }
    componentDidMount() {
        this.unregisterEnquire = enquireIsMobile(ismobile => {
            const { isMobile } = this.state;
            if (isMobile !== ismobile) {
                this.setState({
                    isMobile: ismobile
                });
            }
        });
    }

    render() {
        let { isMobile, isWx } = this.state;
        if (isWx) {
            return null
        }

        return (
            <div className="h100">
                {
                    isMobile ? <Mb> </Mb> : <Web> </Web>
                }
            </div>
        )
    }
}