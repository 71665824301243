import request from '../utils/request';


//用户列表
export function getUserList(params) {
    return request({
        url: '/oauth-set/get-user',
        method: 'GET',
        params
    });
}


export function getClientList(params) {
    return request({
        url: '/oauth-set/client-list',
        method: 'GET',
        params
    });
}


export function getGroupList(params) {
    return request({
        url: '/oauth-set/list-group',
        method: 'GET',
        params
    });
}



export function getUsertOpt(params) {
    return request({
        url: '/user/get-user-by-name',
        method: 'GET',
        params
    });
}



//新建应用
export function postClientInfo(data) {
    return request({
        url: '/oauth-set/create-client',
        method: 'POST',
        data
    });
}



//修改应用
export function updateClientInfo(data) {
    return request({
        url: '/oauth-set/update-client',
        method: 'POST',
        data
    });
}


//删除应用
export function delClientInfo(id) {
    return request({
        url: '/oauth-set/update-client',
        method: 'POST',
        data: {
            client_id: id,
            status: 0,
        }
    });
}


//新建应用组
export function postGroupInfo(data) {
    return request({
        url: '/oauth-set/edit-group',
        method: 'POST',
        data
    });
}



//修改应用组
export function updateGroupInfo(data) {
    return request({
        url: '/oauth-set/edit-group',
        method: 'POST',
        data
    });
}


//删除应用组
export function delGroupInfo(id) {
    return request({
        url: '/oauth-set/edit-group',
        method: 'POST',
        data: {
            group_id: id,
            is_delete: 1,
        }
    });
}




export function addGroupToUser(data) {
    return request({
        url: '/oauth-set/user-add-group',
        method: 'POST',
        data
    });
}


export function addClientToUser(data) {
    return request({
        url: '/oauth-set/user-add-client',
        method: 'POST',
        data
    });
}


export function addClientToGroup(data) {
    return request({
        url: '/oauth-set/group-add-client',
        method: 'POST',
        data
    });
}



export function getOperatorLogList(params) {
    return request({
        url: '/oauth-log/operator-log',
        method: 'GET',
        params
    });
}


export function getLoginLogList(params) {
    return request({
        url: '/oauth-log/login-log',
        method: 'GET',
        params
    });
}



//管理员



export function getAdminList(params) {
    return request({
        url: '/index/list-admin',
        method: 'GET',
        params
    });
}


export function addAdmin(data) {
    return request({
        url: '/index/add-admin',
        method: 'POST',
        data
    });
}



export function delAdmin(data) {
    return request({
        url: 'index/del-admin',
        method: 'POST',
        data
    });
}
