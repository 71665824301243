import React, { Component } from 'react';
import {
    Row,
    Col,
    Form,
    Icon,
    Input,
    Button,
    Radio,
    message
} from 'antd';
import './index.less';
import { Login } from '../../api/login'
import { Base64 } from 'js-base64';
import wxAuth from '../../utils/wxAuth'

export default class Web extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            userName: '',
            password: '',
            activeTab: "qrcode",
            formType: "internal",
            organization: 'qtt'
        };

    }
    componentWillMount() {

    }
    componentDidMount() {
        if (this.state.activeTab === 'qrcode') {
            this.setAuthCode();
        }
        // message.warning(`近期企业微信二维码显示不够稳定，如未出现二维码，请尝试【再次刷新】获取，或切换至【账号登陆】进行认证。`,10);
        

    }
    setAuthCode = () => {
        this.isEwx = wxAuth.isEwx();
        if (!this.isEwx) {
            wxAuth.WwLogin();
        }
    }

    _Login = () => {
        let { userName, password, formType, organization } = this.state;
        if (!userName) {
            message.error('账号不能为空')
        } else if (!password) {
            message.error('密码不能为空')
        } else {
            let type = formType === 'external' ? 3 : (organization === 'qtt' ? 1 : 2);
            let userNameB64 = Base64.encode(userName).toString();
            let passwordB64 = Base64.encode(password).toString();
            Login({
                userName: userNameB64,
                password: passwordB64,
                // userName,
                // password,
                type
            }).then(res => {
                wxAuth.SSO();
            })
        }
    }

    render() {
        let { activeTab, formType } = this.state;
        return (
            <div className="login-page flex_v justify_between">
                <div className="content">
                    <Row gutter={24} className="h100 m0">
                        <Col className="gutter-row h100 flex_v align_center justify_center flex" span={14}>
                            <img src={require('../../assets/images/login-bg.png')} alt="" className="img" />
                        </Col>
                        <Col className="gutter-row h100  flex_v align_center justify_center flex" span={10}>
                            <div className="header">
                                <p className="p1"> 统一账户中心</p>
                                <p className="p2">统一账户 统一登录 安全互通</p>
                            </div>
                            {
                                activeTab === 'qrcode' &&
                                <div className="login_qr login-w">
                                    <div className="tabs  flex_h ">
                                        <div className="tab active" onClick={() => {
                                            this.setState({
                                                activeTab: 'qrcode'
                                            })
                                        }}>
                                            <span>
                                                扫码登录
                                        </span>
                                        </div>
                                        <div className="tab"
                                            onClick={() => {
                                                this.setState({
                                                    activeTab: 'form'
                                                })
                                            }}
                                        >账户登录</div>
                                    </div>
                                    <div className="qrcode flex_v align_center">
                                        <div id="wx_reg" className="login-qrcode"></div>
                                        {/* <img src={require('../../assets/images/qr.png')} alt="" className="qr-img" /> */}
                                    </div>
                                    <div className="tip">
                                        <p><a className="out" href="http://km.qutoutiao.net/pages/viewpage.action?pageId=106842114" target='_blank'>点此处申请权限</a></p>
                                        <p>
                                            打开 <span className="out">企业微信App</span> 扫描二维码登录
                                    </p>
                                        <p>
                                            登录如有问题请企微咨询：PIEE服务
                                    </p>
                                    </div>
                                    <div className=" s flex_h justify_between">
                                        <div className="item">
                                            <img src={require('../../assets/images/icon1.png')} className="icon" alt="" />
                                            <span>
                                                免输入
                                        </span>

                                        </div>
                                        <div className="item">
                                            <img src={require('../../assets/images/icon4.png')} className="icon" alt="" />
                                            <span>
                                                更快
                                        </span>

                                        </div>
                                        <div className="item">
                                            <img src={require('../../assets/images/icon2.png')} className="icon" alt="" />
                                            <span>
                                                更安全
                                        </span>

                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                activeTab === 'form' &&
                                <div className="login_form login-w">
                                    <div className="back"
                                        onClick={() => {
                                            this.setState({
                                                activeTab: 'qrcode'
                                            }, () => {
                                                this.setAuthCode();
                                            })
                                        }}>
                                        <Icon type="left" />
                                        <span>
                                            返回
                                        </span>

                                    </div>
                                    <div className="title">账户登录</div>
                                    <div className="tabs  flex_h mt20">
                                        <div className={['tab', formType === 'internal' ? 'active-form' : ''].join(' ')}
                                            onClick={() => {
                                                this.setState({
                                                    formType: 'internal'
                                                })
                                            }}
                                        >内部用户</div>
                                        <div className={['tab', formType === 'external' ? 'active-form' : ''].join(' ')}
                                            onClick={() => {
                                                this.setState({
                                                    formType: 'external'
                                                })
                                            }}>外部用户</div>
                                    </div>
                                    <Form className="login-form">
                                        <Form.Item>
                                            <Input
                                                onChange={(e) => {
                                                    this.setState({
                                                        userName: e.target.value
                                                    })
                                                }}
                                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                placeholder="请输入域账号"
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <Input.Password
                                                onChange={(e) => {
                                                    this.setState({
                                                        password: e.target.value
                                                    })
                                                }}
                                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                type="password"
                                                placeholder="请输入密码"
                                            />
                                        </Form.Item>
                                        {
                                            formType === 'internal' && <Form.Item>
                                                <Radio.Group onChange={(e) => {
                                                    this.setState({
                                                        organization: e.target.value
                                                    })
                                                }} value={this.state.organization}>
                                                    <Radio value={'qtt'} checked>趣头条</Radio>
                                                    <Radio value={'group'}>集团</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        }

                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" className="login-form-button" onClick={
                                                () => {
                                                    this._Login()
                                                }
                                            }>
                                                登录
                                        </Button>
                                        </Form.Item>
                                    </Form>
                                    <div className="tip">登录如有问题请企微咨询：PIEE服务</div>
                                </div>
                            }

                        </Col>
                    </Row>
                </div>
                {/* <div className="footer"></div> */}

            </div>
        )
    }
}