import React, { Component } from 'react';



export default class Start extends Component {
    constructor(props) {
        super(props);
        props.history.replace('/user/client')
    }
    componentWillMount() {

    }
    componentDidMount() {

    }
    render() {
        return (
            null
        )
    }
}