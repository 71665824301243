import React, { Component } from 'react';
import {
    Select,
    Table,
    Button,
    message,
    Spin,
    Modal
} from 'antd';
import { getUsertOpt, getAdminList, delAdmin, addAdmin } from '../../api/user'
import { getPagination } from '../../utils/util'
import './index.less';
import $$ from 'cmn-utils';
const { debounce } = $$;
const { Option } = Select;

const confirm = Modal.confirm;
export default class AuthList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            modalVisible: false,
            allUser: [],
            userOptions: [],
            pagination: {},
            loading: false,
            selectValue: [],
            fetching: false,
        };
        this.columns = [
            {
                title: '用户名称',
                dataIndex: 'realName',
            },
            {
                title: '邮箱',
                dataIndex: 'email',
            },
            {
                title: '操作',
                dataIndex: '',
                key: 'x',
                render: (text, item) => <a href="javascript:;" className="danger" onClick={
                    () => {
                        this._showDeleteConfirm(item)
                    }
                }>删除</a>,
            },
        ];
        this._throttleFilterUser = debounce(this._filterUser, 500)
    }
    componentWillMount() {

    }
    componentDidMount() {
        this.getList();
    }
    setPagination({ count, page, size }) {
        const pagination = getPagination(count, page, size);
        this.setState({
            pagination,
        })

    }
    getList = (page = 1) => {
        this.setState({
            loading: true
        })
        getAdminList({
            page: page
        }).then(res => {
            this.setPagination(res);
            this.setState({
                data: res.data,
                loading: false
            })
        }).catch(err => {
            this.setState({
                loading: false
            })
        })
    }

    changePage = (page) => {
        this.getList(page);
    }
    _showDeleteConfirm = ({ uid, realName }) => {
        let me = this;
        confirm({
            title: `您确认删除${realName}的权限吗?`,
            content: '删除后不可登录用户管理平台',
            okText: '确认删除',
            okType: 'danger',
            cancelText: '取消',
            onOk: () => {
                delAdmin({ uid }).then(res => {
                    message.success('删除成功')
                    me.getList();
                })
            }
        });
    }


    _filterUser = (query) => {
        if (!query.trim()) {
            return false;
        }
        getUsertOpt({
            name: query,
            type: 0
        }).then(res => {
            this.setState({
                userOptions: res,
                fetching: false
            });
        })
    }
    _onSaveData = () => {
        let data = this.state.selectValue || []
        if (data.length) {
            addAdmin({ uid: data }).then(res => {
                message.success('添加成功');
                this.setState({
                    modalVisible: false,
                    selectValue: []
                }, () => {
                    this.getList()
                })
            }).catch(err => {
                message.error('添加失败');
            })
        }
    }
    render() {
        const { data, pagination, loading, userOptions, fetching } = this.state;
        return (
            <div className="user-page" >
                <div className="nav">
                    <p>权限列表</p>
                </div>
                <header className="search-bar" style={{ textAlign: "right" }}>
                    <Button type="primary" htmlType="submit"
                        onClick={() => {
                            this.setState({
                                modalVisible: true
                            })
                        }}>
                        新增权限用户
                    </Button>
                </header>
                <div className="content">
                    <Table
                        rowKey="uid"
                        locale={{
                            emptyText: (<span>
                                无相关数据
                            </span>)
                        }}
                        columns={this.columns}
                        dataSource={data}
                        loading={loading}
                        pagination={{  // 分页
                            ...pagination,
                            onChange: (page) => {
                                this.changePage(page)
                            },
                        }} />
                    <Modal
                        title='新增用户'
                        onOk={() => {
                            this._onSaveData()
                        }}
                        okText="保存"
                        cancelText='取消'
                        onCancel={() => {
                            this.setState({
                                modalVisible: false
                            })
                        }}
                        visible={this.state.modalVisible}
                    >
                        <p>
                            选择用户:
                        </p>
                        <Select
                            showSearch
                            placeholder="搜索用户"
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                            optionLabelProp="name"
                            onSearch={(qurey) => {
                                this.setState({
                                    fetching: true
                                })
                                this._throttleFilterUser(qurey)
                            }}
                            filterOption={
                                () => {
                                    return true
                                }
                            }
                            onChange={(value) => {
                                this.setState({
                                    selectValue: value
                                })
                            }}
                            style={{ width: '100%' }}
                        >
                            {userOptions.map(item => (
                                <Option key={item.id} name={item.realName}>
                                    <span>{item.realName}</span>
                                    <span style={{ marginLeft: '10px', fontSize: '12px' }}>{item.email}</span>
                                </Option>
                            ))}
                        </Select>
                    </Modal>
                </div>
            </div>
        )
    }
}