import React, { Component } from 'react';
import {
    Form,
    Input,
    Table,
    Button,
    Modal,
    message,
    Tag
} from 'antd';
import { getClientList, getGroupList, postGroupInfo, updateGroupInfo, delGroupInfo, addClientToGroup } from '../../api/user'
import { getPagination } from '../../utils/util'
import './index.less';
import AddClient from './addClient'

const confirm = Modal.confirm;
const colors = ["magenta", "red", "volcano", "orange", "gold", "lime", "green", "cyan", "blue", "geekblue", "purple",]

export default class Group extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filter: {
                group_name: '',
            },
            group_id: '',
            formType: 'new',
            pagination: {},
            editData: {},
            clientOpt: [],
            modalVisible: false,
            modalGroupVisible: false,
            loading: false
        };

        this.columns = [
            {
                title: '应用组id',
                dataIndex: 'group_id',
            },
            {
                title: '应用组名称',
                dataIndex: 'group_name',
            },

            {
                title: '应用',
                dataIndex: 'groups',
                width: "40%",
                render: (text, item) => {
                    return this._renderTag(item.client)
                }
            },
            {
                dataIndex: 'update_time',
                title: '更新时间',
            },
            {
                title: '操作',
                dataIndex: '',
                dataIndex: '8',
                render: (text, item) => {
                    return (
                        <div className="">
                            <a href="javascript:;" className="a-btn" onClick={
                                () => {
                                    this._edit(item)
                                }
                            }>编辑</a>
                            <a href="javascript:;" className="a-btn danger ml10" onClick={
                                () => {
                                    this._showDeleteConfirm({ group_id: item.group_id, group_name: item.group_name })
                                }
                            }>删除</a>
                            <a href="javascript:;" className="a-btn success ml10" onClick={
                                () => {
                                    this._RClient(item.client, item.group_id)
                                }
                            }>关联应用</a>
                        </div>

                    )
                }



            },
        ];
    }
    componentWillMount() {

    }
    componentDidMount() {
        this.getList();
        this.getOptList()
    }

    getOptList = () => {
        getClientList({
            size: 10000,
            page: 1
        }).then(res => {
            this.setState({
                clientOpt: res.data
            })
        })
    }

    setPagination({ total, page, pageSize }) {
        const pagination = getPagination(total, page, pageSize);
        this.setState({
            pagination,
        })

    }
    _renderTag(data = []) {
        return (
            data.map((t, i) => {
                let index = parseInt(Math.random() * 11) + 1;
                let color = colors[index - 1];
                return (
                    <Tag key={i} color={color} style={{ marginTop: '10px', fontSize: '13px' }}>{t.client_name || t.group_name}</Tag>
                )

            })
        )
    }
    renderType = (type) => {
        let strmap = ['异常', 'qtt', 'infotech', '全部', ' 全不'];
        return strmap[parseInt(type)]
    }
    getList = (page = 1) => {
        this.setState({
            loading: true
        })
        getGroupList({
            ...this.state.filter,
            page: page,
            size: 20
        }).then(res => {
            console.log(res)
            this.setPagination(res);
            this.setState({
                data: res.data,
                loading: false
            })
        }).catch(err => {
            this.setState({
                loading: false
            })
        })
    }
    changePage = (page) => {
        this.getList(page);
    }
    changeFilter = (v, key) => {
        let tpl = {};
        tpl[key] = v;
        this.setState({
            filter: Object.assign({}, this.state.filter, tpl)
        })
    }
    _renderText = (text) => {
        // let len = text.length;
        // if (len > 100) {
        //     return (
        //         <Tooltip placement="topLeft" title={text}>
        //             <span>{`${text.substring(0, 40)}...`}</span>
        //         </Tooltip>
        //     )
        // } else {
        //     return text
        // }
        return text

    }

    _changeVisible = (status) => {
        this.setState({
            modalVisible: status
        })
    }

    _edit = (item) => {
        this.setState({
            modalGroupVisible: true,
            group_id: item.group_id,
            group_name: item.group_name,
            formType: 'edit'
        })
    }

    _showDeleteConfirm = ({ group_id, group_name }) => {
        let me = this;
        confirm({
            title: `您确认删除${group_name}应用组吗?`,
            content: '删除后不可恢复',
            okText: '确认删除',
            okType: 'danger',
            cancelText: '取消',
            onOk: () => {
                delGroupInfo(group_id).then(res => {
                    message.success('删除成功')
                    me.getList();
                })
            }
        });
    }

    _RClient = (item, group_id) => {
        let ids = item.map(v => {
            return v.client_id
        })
        this.setState({
            group_id,
            modalVisible: true,
            editData: ids,
            opts: this.state.clientOpt,
        })
    }

    _onSaveData = (data) => {

        addClientToGroup({
            group_id: this.state.group_id,
            client_ids: data.join(),
        }).then(res => {
            message.success('关联成功');
            this.setState({
                modalVisible: false,
            }, () => {
                this.getList()
            })
        }).catch(err => {
            message.error(err || '关联失败');
        })
    }
    _groupPost = () => {

        if (this.state.formType === 'new') {
            postGroupInfo({
                group_name: this.state.group_name
            }).then(res => {
                message.success('添加成功');
                this.setState({
                    modalGroupVisible: false,
                }, () => {
                    this.getList()
                })
            }).catch(err => {
                message.error(err || '添加失败');
            })
        } else {
            updateGroupInfo({
                group_id: this.state.group_id,
                group_name: this.state.group_name
            }).then(res => {
                message.success('修改成功');
                this.setState({
                    modalGroupVisible: false,
                    group_id: '',
                    group_name: '',
                }, () => {
                    this.getList()
                })
            }).catch(err => {
                message.error(err || '修改失败');
            })
        }
    }


    render() {
        const { data, pagination, loading, editData, opts, formType } = this.state;
        return (
            <div className="group-page">
                <div className="nav">
                    <p>应用组列表</p>
                </div>
                <header className="search-bar">
                    <Form layout="inline" >
                        <Form.Item label="应用组名">
                            <Input
                                size='large'
                                allowClear
                                placeholder="请输入应用组名"
                                onChange={
                                    (e) => {
                                        this.changeFilter(e.target.value, 'client_name')
                                    }
                                }
                                onPressEnter={() => {
                                    this.getList()
                                }}
                            />
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary" htmlType="submit"
                                onClick={() => {
                                    this.getList()
                                }}>
                                查询
                             </Button>
                        </Form.Item>

                        <Form.Item >
                            <Button className="success-btn" htmlType="submit"
                                onClick={() => {
                                    this.setState({
                                        modalGroupVisible: true,
                                        formType: 'new',
                                        group_name: '',
                                        group_id: '',
                                    })
                                }}>
                                新增应用组
                             </Button>
                        </Form.Item>

                    </Form>
                </header>
                <div className="content">
                    <Table
                        rowKey="group_id"
                        locale={{
                            emptyText: (<span>
                                无相关数据
                            </span>)
                        }}
                        columns={this.columns}
                        dataSource={data}
                        loading={loading}
                        pagination={{  // 分页
                            ...pagination,
                            onChange: (page) => {
                                this.changePage(page)
                            },
                        }} />
                </div>

                <Modal
                    title={formType === 'edit' ? '编辑应用组' : '新建应用组'}
                    onOk={() => {
                        this._groupPost();
                    }}
                    okText="保存"
                    cancelText='取消'
                    onCancel={() => {
                        this.setState({
                            modalGroupVisible: false,
                        })
                    }}
                    visible={this.state.modalGroupVisible}
                >
                    <p>
                        应用组名称:
                    </p>
                    <Input
                        size='large'
                        allowClear
                        placeholder="请输入应用组名"
                        value={this.state.group_name}
                        onChange={
                            (e) => {
                                this.setState({
                                    group_name: e.target.value
                                })
                            }
                        }
                    />

                </Modal>
                <AddClient editData={editData} opts={opts} onSaveData={this._onSaveData} modalVisible={this.state.modalVisible} changeVisible={this._changeVisible}></AddClient>
            </div>
        )
    }
}